export const getStyleSettings = (blok: {
  padding_top?: string | number
  padding_bottom?: string | number
  background_color?: string | number
  rounded_top?: boolean
}) => {
  const classes = []

  if (blok.padding_top) {
    switch (blok.padding_top) {
      case 'small':
        classes.push(' pt-14 lg:pt-20 ')
        break
      case 'medium':
        classes.push(' pt-16 lg:pt-30 ')
        break
      case 'large':
        classes.push(' pt-16 lg:pt-40 ')
        break
    }
  }

  if (blok.padding_bottom) {
    switch (blok.padding_bottom) {
      case 'small':
        classes.push(' pb-14 lg:pb-20 ')
        break
      case 'medium':
        classes.push(' pb-16 lg:pb-30 ')
        break
      case 'large':
        classes.push(' pb-16 lg:pb-40 ')
        break
    }
  }

  if (blok.background_color) {
    classes.push(` bg-${blok.background_color} `)
  }

  return classes
}
